$(document).ready(function() {
    var idAddress = $('#shipping-address-select').val();
    fillAddressInfos(idAddress);
    
    if ($('#shipping-address-infos-wrapper').length) {
        $(this).find('#shipping-address-select').change(function() {
            idAddress = $(this).val();
            fillAddressInfos(idAddress);
        });
    }
});


function fillAddressInfos(addressId) {
    jQuery.each(prestashop.customer.addresses, function (key, address) {
        if (key === addressId) {
            $('#shipping-address-infos-alias').text(address.alias);
            $('#shipping-address-infos-name').text(address.firstname +" "+ address.lastname);
            $('#shipping-address-infos-address').text(address.address1 +" "+ address.address2);
            $('#shipping-address-infos-city').text(address.postcode +" "+ address.city);
            $('#shipping-address-infos-country').text(address.country);
        }
    });
};